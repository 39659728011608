<template>
  <div class="w-full max-h-screen bg-background h-full">
    <page-header backTo="fleets" title="Edit Fleet">
      <is-authorized :permissions="['read:fleets']">
        <button
          @click="printVehicleRecord()"
          type="button"
          class="flex items-center bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M6 9V2h12v7M6 18H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2"
            />
            <path d="M6 14h12v8H6z" />
          </svg>
          <span>Print Vehicle Record</span>
        </button>
      </is-authorized>
      <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
        <button
          @click="deleteVehicle()"
          type="button"
          class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
            />
          </svg>
          <span>Delete</span>
        </button>
      </is-authorized>
    </page-header>
    <main class="p-6 pb-20 bg-background flex-1 overflow-y-scroll h-full">
      <div v-if="fleet">
        <div class="w-full bg-white p-5 rounded shadow-md flex">
          <div class="w-3/3">
            <h2 class="uppercase text-3xl mb-1">
              {{ fleet.manufacturer }} {{ fleet.make }}
            </h2>
            <h3>
              <div v-if="fleet.license_plate" class="flex items-center py-1">
                <p class="uppercase text-2xl px-2 py-1">
                  {{ fleet.license_plate }}
                </p>
              </div>
            </h3>
          </div>
        </div>

        <!-- Child tabs start here -->
        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="w-full mb-6">
            <div class="flex -mx-2 mr-2">
              <button
                @click="currentTab = 'vehicleinfo'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'vehicleinfo',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'vehicleinfo',
                }"
              >
                <span>Vehicle information</span>
              </button>
              <button
                @click="currentTab = 'registeredKeeper'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'registeredKeeper',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'registeredKeeper',
                }"
              >
                <span>Registered Keeper</span>
              </button>
              <button
                @click="currentTab = 'assignment'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'assignment',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'assignment',
                }"
              >
                <span>Assignment</span>
              </button>
              <button
                @click="currentTab = 'mot'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'mot',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'mot',
                }"
              >
                <span>MOT</span>
              </button>

              <button
                @click="currentTab = 'insurance'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'insurance',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'insurance',
                }"
              >
                <span>Insurance</span>
              </button>

              <button
                @click="currentTab = 'breakdown'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'breakdown',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'breakdown',
                }"
              >
                <span>Breakdown</span>
              </button>

              <button
                @click="currentTab = 'service'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'service',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'service',
                }"
              >
                <span>Service</span>
              </button>
              <button
                @click="currentTab = 'documents'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'documents',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'documents',
                }"
              >
                <span>Documents</span>
              </button>
            </div>
          </div>
          <div v-if="currentTab == 'vehicleinfo'">
            <VehicleInformation :fleet="fleet" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'assignment'">
            <Assignment :assignments="fleet.assignment" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'mot'">
            <MOT :mots="fleet.mot" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'insurance'">
            <Insurance :insurances="fleet.insurance" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'breakdown'">
            <Breakdown :breakdowns="fleet.breakdown" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'service'">
            <Service :services="fleet.service" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'documents'">
            <VehicleDocuments :fleet="fleet" @complete="loadFleet" />
          </div>
          <div v-if="currentTab == 'registeredKeeper'">
            <RegisteredKeeper
              :registeredKeepers="fleet.registeredKeeper"
              @complete="loadFleet"
            />
          </div>
          <div v-if="error">
            <div
              class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
            >
              <p>
                Couldn't load fleet information....:
                {{ error.statusText }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="bg-white rounded shadow-md flex items-center justify-center py-20"
      >
        <spinner :color="'text-black'" :size="10" />
      </div>
    </main>

    <transition name="slide-bottom">
      <view-attachment
        v-if="viewingAttachmentOpen"
        :attachment="viewingAttachment"
        @close="viewingAttachmentOpen = false"
      />
    </transition>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Panel = () => import("@/components/Panel.vue");
const Spinner = () => import("@/components/Spinner.vue");
const AddEditVehicle = () =>
  import("@/components/Fleets/Edit/AddEditVehicle.vue");
const VehicleInformation = () =>
  import("@/components/Fleets/VehicleInformation.vue");
const Assignment = () => import("@/components/Fleets/Assignment.vue");
const MOT = () => import("@/components/Fleets/MOT.vue");
const Insurance = () => import("@/components/Fleets/Insurance.vue");
const Breakdown = () => import("@/components/Fleets/Breakdown.vue");
const Service = () => import("@/components/Fleets/Service.vue");
const RegisteredKeeper = () =>
  import("@/components/Fleets/RegisteredKeeper.vue");
const VehicleDocuments = () => import("@/components/Fleets/Documents.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

import notificationMixin from "@/mixins/notificationMixin";

import printJS from "print-js";
export default {
  mixins: [notificationMixin],
  name: "FleetView",
  components: {
    PageHeader,
    Panel,
    Spinner,
    AddEditVehicle,
    VehicleInformation,
    Assignment,
    MOT,
    Insurance,
    Breakdown,
    Service,
    VehicleDocuments,
    RegisteredKeeper,
    IsAuthorized,
  },
  props: {
    fleetId: String,
  },
  data() {
    return {
      fleet: null,
      currentTab: "vehicleinfo",
      error: null,
      editDetailsPanelOpen: false,
      vehicleToEdit: {},
      viewingAttachment: {},
      viewingAttachmentOpen: false,
      htmlTemplate: "",
    };
  },
  async mounted() {
    await this.loadFleet();
  },
  methods: {
    loadFleet: async function () {
      try {
        let result = await this.FleetService.getFleetById(this.fleetId);
        this.fleet = { ...result };
      } catch (error) {}
    },
    dynamicValues: function () {
      const dynamicvalue = {
        registration: this.fleet.license_plate,
        manufacturer: this.fleet.manufacturer,
        model: this.fleet.make,
        year: this.fleet.manufacture_year,
        mileage: "N/A", // Assuming mileage is not in fleet
        fuelType: this.fleet.fuel_type,
        color: this.fleet.colour,
        engineSize: this.fleet.engine_size, // Assuming engineSize is not in fleet
        chassisNumber: this.fleet.chassis_no, // Assuming chassisNumber is not in fleet
        seatingCapacity: this.fleet.seat_capacity,
        assignedTo:
          this.fleet.assignment.length > 0
            ? this.fleet.assignment[0].staff_name
            : "N/A",
        taxExpiry: this.$moment(this.fleet.tax_expiry).format("DD/MM/YYYY"),
        motExpiry:
          this.fleet.mot.length > 0
            ? this.$moment(this.fleet.mot[0].expiry_date).format("DD/MM/YYYY")
            : "N/A",
        purchaseDate: this.$moment(this.fleet.purchase_date).format(
          "DD/MM/YYYY",
        ),
        purchaseMileage: this.fleet.purchase_mileage,
        notes: this.fleet.notes,
        registeredKeeper:
          this.fleet.registeredKeeper && this.fleet.registeredKeeper.length > 0
            ? `${this.fleet.registeredKeeper[0].name}, ${this.fleet.registeredKeeper[0].address1}, ${this.fleet.registeredKeeper[0].postcode}`
            : "N/A",
      };
      return dynamicvalue;
    },

    async loadHtmlTemplate() {
      try {
        const response = await fetch("/report_fleet.html");
        if (!response.ok) {
          throw new Error("Failed to load report template");
        }
        this.htmlTemplate = await response.text();
      } catch (error) {
        this.error = error.message;
      }
    },

    // Replaces the placeholders with the actual data
    replacePlaceholders() {
      let htmlContent = this.htmlTemplate;
      const dynamicData = this.dynamicValues();
      Object.keys(dynamicData).forEach((key) => {
        const value = dynamicData[key];
        htmlContent = htmlContent.replace(new RegExp(`{{${key}}}`, "g"), value);
      });
      return htmlContent;
    },

    // Prints the vehicle record
    async printVehicleRecord() {
      await this.loadHtmlTemplate();
      // If there's an error loading the template, don't attempt to print
      if (this.error) {
        console.error(this.error); // Log the error or display it to the user
        return;
      }
      const printContent = this.replacePlaceholders();

      printJS({
        printable: printContent,
        type: "raw-html",
      });
    },

    async handleDeleteVehicle() {
      await this.confirmAction({
        message: `Are you sure you want to delete the vehicle '${this.fleet.manufacturer} ${this.fleet.make} with license plate ${this.fleet.license_plate}'?`,
        accept: this.performDeletion,
      });
    },
    async performDeletion() {
      try {
        this.isBusy = true;
        await this.FleetService.deleteVehicle(this.fleet.fleet_id);
        this.notifySuccess(
          `Vehicle '${this.fleet.license_plate}' was successfully deleted.`,
        );
        await this.$router.push({ name: "fleets" });
      } catch (error) {
        this.notifyError(
          "An error occurred while deleting the vehicle. Please try again later.",
        );
      } finally {
        this.isBusy = false;
      }
    },
    deleteVehicle() {
      this.handleDeleteVehicle();
    },
  },
};
</script>
